.logo {
    display: inline-block;
    position: relative;
    width: 10rem;
}
.logo.lg {
    width: 14rem;
}
.logo img {
    display: block;
    object-fit: contain;
    width: 100%;
    height: 100%;
}

@media(min-width:600px) {
    .logo,
    .logo.md {
        width: 12rem;
    }
    .logo.mdlg {
        width: 16rem;
    }
    .logo.lg {
        width: 20rem;
    }
}