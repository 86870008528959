.layout {
    display: grid;
    grid-template-columns: 1fr;
}

.video {
    z-index: 2;
    aspect-ratio: 16/9;
    background-color: rgb(36, 36, 36);
}

.graphic {
    position: relative;
    z-index: -1;
}

.graphicWrapper {
    aspect-ratio: 800/600;
    margin-top: -40vw;
    margin-bottom: var(--space-md);
}



@media(min-width:800px){

    .layout {
        display: grid;
        grid-template-columns: var(--layout-split);
        margin-bottom: calc(var( --space-md) * -1);
    }

    .impact {
        grid-area: 1/2 / 2/3;
    }

    .videoHeading {
        grid-area: 1/1 / 3/2;
        display: flex;
        align-items: flex-end;
        text-align: right;
    }

    .video {
        grid-area: 2/2 / 4/3;
    }

    .graphic {
        position: relative;
        grid-area: 4/2 / 5/3;
        padding-right: 12vw;
        padding-top: 20vh;
        height: 15vh;
    }


    .quote {
        grid-area: 3/1 / 6/2;
    }

}



/* impact grid --------------------------------------------------------- */

.impactGrid, .impactGridThree {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
}
.impactLayout {
    line-height: 1;
}
.impactFigure {
    display: block;
    border-top: 1px solid white;
    padding-top: .52rem;
}
.impactLabel {
    display: block;
}

@media(min-width:800px){
    .impactGrid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 2rem;
    }
    .impactGridThree {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
    }
}