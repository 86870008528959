@media(max-width:799px){
    .layout  {
        display: grid;
        background-color: var(--secondary);
    }
    .hero {
        grid-row-start: 2;
        overflow: hidden;
        height: 90vw;
    }
}


@media(min-width:800px){

    .layout {
        min-height: calc(20vh - var(--header-height));
        padding-top: var(--header-height);
        background-color: var(--secondary);
    
        display: grid;
        grid-template-columns: var(--layout-split);
        overflow: hidden;
    }
    .headline {
        grid-area: 1/1 / 2/3;
    }
    .hero {
        grid-area: 2/2 / 3/2;
        margin-bottom: -10rem;
    }
}


@media(min-width:1200px){

    .layout {
        /* min-height: calc(100vh - var(--header-height)); */
    }
    .headline {
        grid-area: 1/1 / 2/2;
        z-index: 2;
        align-self: center;
    }
    .hero {
        grid-area: 1/2 / 2/3;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-right: 12vw;
        margin-bottom: -10rem;
    }
    .heroWrapper {
        aspect-ratio: 800/400;
    }

}
